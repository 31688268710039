/* Layout types and other constants */

export const LAYOUT_VERTICAL = 'vertical';
export const LAYOUT_HORIZONTAL = 'topnav';
export const LAYOUT_DETACHED = 'detached';

export const LAYOUT_WIDTH_FLUID = 'fluid';
export const LAYOUT_WIDTH_BOXED = 'boxed';

export const LEFT_SIDEBAR_THEME_DEFAULT = 'default';
export const LEFT_SIDEBAR_THEME_LIGHT = 'light';
export const LEFT_SIDEBAR_THEME_DARK = 'dark';

export const LEFT_SIDEBAR_TYPE_FIXED = 'fixed';
export const LEFT_SIDEBAR_TYPE_CONDENSED = 'condensed';
export const LEFT_SIDEBAR_TYPE_SCROLLABLE = 'scrollable';

export const LAYOUT_REGISTER = 'register';
export const LAYOUT_LOGIN = 'login';
export const LAYOUT_DEFAULT = 'default-layout';
export const LAYOUT_SETUP = 'setup';

/* changed due to new signup flow 05/14/2024 */

export const SETUP_MENU_ITEMS = [
    { id: 'deposit-agreements', name: 'Deposit Agreements', active: false, passed: false },
    { id: 'link-bank-account', name: 'Link Bank Account', active: false, passed: false },
    { id: 'customer-info', name: 'Customer Info', active: false, passed: false },
    { id: 'customer-ssn', name: 'SSN', active: false, passed: false },
    { id: 'kyc-in-progress', name: 'Verify Identity', active: false, passed: false },
    { id: 'add-to-crb', name: "Create Deposit Account", active: false, passed: false },
    { id: 'add-to-galileo', name: "Create Debit Card", active: false, paseed: false },
    { id: 'complete', name: 'Complete', active: false, passed: false },
];

export const SETUP_MENU_ITEM_MAP = {
    'deposit-agreements': 'deposit-agreements',
    'link-bank-account': 'link-bank-account',
    'customer-info': 'customer-info',
    'customer-ssn': 'customer-ssn',
    complete: 'complete',
};
